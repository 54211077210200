import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <header className="header">
      <div className="logo">WebStream Media</div>
    </header>
  );
};

export default Header;
